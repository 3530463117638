/* General Sans Variable */

@font-face {
  font-family: 'General Sans Variable';
  src: url('GeneralSans-Variable.woff2') format('woff2'),
  url('GeneralSans-Variable.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
    font-family: 'Marsden Compressed';
    src: url('Marsden-Cm-Black.eot');
    src: local('Marsden Compressed Black'), local('Marsden-Cm-Black'),
        url('Marsden-Cm-Black.eot?#iefix') format('embedded-opentype'),
        url('Marsden-Cm-Black.woff2') format('woff2'),
        url('Marsden-Cm-Black.woff') format('woff'),
        url('Marsden-Cm-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Marsden Slim';
    src: url('Marsden-S-Bold.eot');
    src: local('Marsden Slim Bold'), local('Marsden-S-Bold'),
    url('Marsden-S-Bold.eot?#iefix') format('embedded-opentype'),
    url('Marsden-S-Bold.woff2') format('woff2'),
    url('Marsden-S-Bold.woff') format('woff'),
    url('Marsden-S-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Marsden Slim';
    src: url('Marsden-S-Regular.eot');
    src: local('Marsden Slim'), local('Marsden-S-Regular'),
    url('Marsden-S-Regular.eot?#iefix') format('embedded-opentype'),
    url('Marsden-S-Regular.woff2') format('woff2'),
    url('Marsden-S-Regular.woff') format('woff'),
    url('Marsden-S-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Marsden Slim Semi';
    src: url('Marsden-S-SemiBold.eot');
    src: local('Marsden Slim Semi Bold'), local('Marsden-S-SemiBold'),
    url('Marsden-S-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('Marsden-S-SemiBold.woff2') format('woff2'),
    url('Marsden-S-SemiBold.woff') format('woff'),
    url('Marsden-S-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
